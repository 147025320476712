.sidebarToggle{
    font-size: 2rem;
    color: var(--light-text-color);
    cursor: pointer;
}

@media (min-width: 711px) {
    .sidebarToggle {
        display: none;
    }
}
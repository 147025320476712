.button{
    width: 100%;

    border-style: solid;
    border-radius: 2rem;
    cursor: pointer;

    padding: 1rem;

    color: var(--light-text-color);
    display: flex;
    justify-content: center;
}

.button p{
    margin: auto 5%;
}

.button:hover,
.button:active,
.button.active{
    filter: brightness(110%);
}
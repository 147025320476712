:root{
    --valid: lime;
    --focus: orange;
    --invalid: red;
    --blur: #ccc;
}

.form{
    display: flex;
    flex-flow: column;
    padding: 5%;
    grid-gap: 1rem;
}

.detail{
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
}

.input{
    height: 2rem;
    border-radius: 0.25rem;
    border: 2px solid var(--color, var(--blur));
    padding-inline: 1rem;
    background-color: var(--light-text-color);
}

input:focus {
    outline-offset: 3px;
}

.detail:has(:invalid) {
    --color: var(--invalid);
}

.detail:has(:invalid:not(:focus)) {
    animation: shake 0.65s;
}

.detail:has(:focus) {
    --color: var(--focus);
}

.detail:has(:valid) {
    --color: var(--valid);
}

.detail:has(:placeholder-shown) {
    --color: var(--blur);
}

.detail:has(:placeholder-shown:not(:focus)) {
    animation: none;
}

.form:valid button{
    transition: 1s;
    width: 100%;

    border-style: solid;
    border-radius: 2rem;
    cursor: pointer;
    
    padding: 0.75rem;

    background-color: var(--green-main);
    border-color: var(--green-main);
    color: var(--light-text-color);
    display: flex;
}

.button{
    width: 100%;

    border-style: solid;
    border-radius: 2rem;
    cursor: pointer;
    
    padding: 0.75rem;

    background-color: #ccc;
    border-color: #ccc;
    color: var(--dark-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover,
.button:active,
.button.active{
    filter: brightness(110%);
    cursor: no-drop;
}


@keyframes shake {
    0%, 100%{
        transform: translateX(0);
    }
    20%, 40%, 60%, 80%{
        transform: translateX(-3%);
    }
    10%, 30%, 50%, 70%, 90%{
        transform: translateX(3%);
    }
}


.textCard{
    border: solid var(--grey-main);
    background-color: var(--grey-main);
    color: var(--light-text-color);
    padding: 3%;
}

.textCard h5{
    font-weight: 1000;
    margin-bottom: 5%;
    color: var(--red-accent);
}
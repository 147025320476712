.scrollDownBtn{
    position: absolute;
    bottom: 5rem;
    height: 3rem;
    animation: moveDown 5s infinite;
}

@keyframes moveDown{
    33%{
        opacity: 0.66;
    }
    66%{
        transform: translateY(2rem);
        opacity: 0.33;
    }
}
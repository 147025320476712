.defaultLink a{
    display: block;
    text-decoration: none;
    color: var(--light-text-color);
    margin-block: 5%;
}

.defaultLink a:hover,
.defaultLink a:active,
.defaultLink a.active{
    transition: .5s;
    cursor: pointer;
    color: var(--red-accent);
}



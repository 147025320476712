.heroSection{
    min-width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
}

.heroSection:before{
    min-width: 100%;
    min-height: 80vh;
    content: '';
    position: absolute;

    background-image: url(/src/assets/heroImages/4.jpg);
    background-size: cover;
    background-position: center;
    filter: brightness(50%);
    background-attachment: fixed;
}

.heroText{
    text-align: center;
    position: relative;
    display: flex;
    flex-flow: column;
    padding-inline: 5%;
    margin-top: 20vh;
    color: var(--light-text-color);
    align-items: center;
    text-shadow: 0 0 20px var(--secondary-bg-color);
    grid-gap: 0.66rem;
}

.heroText h1{
    font-weight: 1000;
}

.infoSection{
    background-color: var(--main-bg-color);
    max-width: 100vw;
    padding: 5%;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-top: -2rem;
    position: relative;
    box-shadow: 0 0 20px var(--secondary-bg-color);
}

.contentSection{
    padding-bottom: 3rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    background-attachment: fixed;
}

.contentSection h4{
    text-align: center;
}

.contactsLayout{
    padding: 1rem;
    display: grid;
    grid-template-columns: 2fr 2fr;
}

.contactsBlock{
    display: flex;
    flex-flow: column;
}

@media (max-width: 710px) {
    .contactsLayout{
        grid-template-columns: 1fr;
        grid-gap: 3rem;
    }
    
    .heroSection:before{
        background-attachment: initial;
    }
}
.footer{
    position: relative;
    display: flex;
    box-sizing: border-box;

    background-color: var(--secondary-bg-color);
    width: 100%;
    
    color: var(--light-text-color);

    margin-top: auto;
}

.footerLayout{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    padding: 5%;
}

.footerBlock{
    border-left: 3px solid var(--grey-main);
    padding-inline: 5%;
}

.footlinks{
    list-style: none;
    display: display;
    flex-flow: column;
    padding-inline: 5%;
}

.contactLinks{
    display: flex;
    flex-flow: column;
    padding-inline: 5%;
}

.customLink{
    display: block;
    color: var(--light-text-color);
    text-decoration: none;
}

.customLink:hover,
.customLink:active,
.customLink.active{
    color: var(--blue-main);
    transition: .5s;
    cursor: pointer;
}

.map{
    border-left: 3px solid var(--grey-main);
    padding-inline: 5%;
}

.rights{
    display: flex;
    justify-content: space-between;
}

.rights p{
    font-family: 'DM Serif Display', serif;
    font-size: small;
    padding-inline: 5%;
}

@media (max-width: 710px) {
    .footerLayout{
        font-size: medium;
        display: flex;
        flex-flow: column;
    }
}

@media (max-width: 400px) {
    .footerLayout{
        font-size: smaller;
    }
}
.mainContent{
    position: relative;
    margin: 0;
    padding-bottom: 2rem;
    background-image: linear-gradient(var(--secondary-bg-color), #424242);
    z-index: 0;
}

@media (max-width: 650px) {
    .mainContent{
        font-size: medium;
    }
}

@media (max-width: 400px) {
    .mainContent{
        font-size: smaller;
    }
}
.sidebar{
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    color: var(--light-text-color);
    background-color: var(--secondary-bg-color);

    z-index: 200;
    transition: transform 0.1s ease-out;

    display: flex;
    flex-flow: column;
}

.open {
    top: 10vh;
    transform: translateY(0);
}

.close {
    transform: translateY(-100%);
}

@media (min-width: 711px) {
    .sidebar {
        display: none;
    }
}
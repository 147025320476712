.nav{
    background-color: var(--secondary-bg-color);
}

.navbar{
    position: relative;
    display: flex;
    flex-flow: row;
    min-height: 10vh;
    width: 100%;
    padding-block: 1rem;
    
    grid-gap: 5%;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
}

.logo{
    white-space: nowrap;
    color: #dc143c;
    margin-inline: 5%;
    font-weight: 1000;
    font-size: 2rem;
    font-family: 'DM Serif Display', serif;
}

@media (max-width: 710px) {
    .DesktopOnly{
        display: none;
    }
}
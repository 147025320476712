.defaultLink a{
    margin: 0 1rem;
    display: block;
    text-decoration: none;
    color: var(--light-text-color);
    border-bottom: solid transparent;
}

.defaultLink a:hover,
.defaultLink a:active,
.defaultLink a.active{
    transition: .5s;
    cursor: pointer;
    color: var(--red-accent);
    border-bottom: solid var(--red-accent);
}

.uniqueLink a{
    margin: 0 1rem;
    padding: 5px;
    display: block;
    text-decoration: none;
    border-radius: 0.5rem;
    color: var(--light-text-color);
    background-color: var(--red-accent);
    border: 2px solid var(--red-accent);
}

.uniqueLink a:hover,
.uniqueLink a:active,
.uniqueLink a.active{
    transition: .5s;
    cursor: pointer;
    color: var(--red-accent);
    background-color: transparent;
    border: 2px solid var(--red-accent);
}

@media (max-width: 710px){
    .defaultLink a{
        margin: 10px 0;
    }

    .uniqueLink a{
        margin: 10px 0;
    }
}


.infoCard{
    border-radius: 0.5rem;
    border: solid var(--grey-main);
    background-color: var(--grey-main);
    color: var(--light-text-color);
    padding: 7%;
    align-items: center;
    display: flex;
    flex-flow: column;
}

.infoCard p{
    margin: auto;
    margin-top: 10%;
}

.infoCard:hover{
    cursor: pointer;
    filter: brightness(110%);
}

.sideCard{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin-block: 3%;

    min-width: auto;
    min-height: auto;
    color: var(--light-text-color);
}

.sideCard p{
    margin: auto;
    margin-left: 5%;
}

.sideCard a{
    margin: auto;
    margin-left: 5%;
    text-decoration: none;
}
.navlinks{
    list-style: none;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: baseline;
    margin: 0;
    padding: 0;
    row-gap: 1rem;
}

@media (max-width: 710px){
    .navlinks{
        display: flex;
        flex-flow: column;
        margin-block: 3% 9%;
        margin-inline: 10%;
        align-items: unset;
        flex-wrap: unset;
        row-gap: 0;
    }
}
:root {
  --main-bg-color: #f6f6f6;
  --secondary-bg-color: #090909;
  --light-text-color: #f6f6f6;
  --dark-text-color: #090909;
  --grey-main: #242424;
  --blue-main: #004d99;
  --green-main: #008000;
  --red-accent: #dc143c;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar{
  width: 0.75rem;
}

::-webkit-scrollbar-track{
  background: var(--secondary-bg-color);
}

::-webkit-scrollbar-thumb{
  background: grey;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover{
  background: hsl(0, 0%, 63%);
}